<template>
    <header>
        <div class="line-border-top">
            <div class="line-border-bottom">
                <div class="container">
                    <h1>
                        {{ title }}
                    </h1>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    props: {
        title: String
    }
}
</script>

<style scoped>

/*  Mobile  */

header {
    order: 0;
}

/*  Tablet  */

@media screen and (min-width: 600px) and (max-width: 900px) {
    header {
        flex-basis: 100%;
        flex-grow: 1;
    }
}

/*  Desktop  */

@media screen and (min-width: 900px) {
    header {
        flex-basis: 100%;
        flex-grow: 1;
    }
}

header {
    text-align: center;
    font-family: 'Fredoka One', serif;
    font-size: x-large;
    color: rgba(221, 148, 31, 1);
    text-shadow: 2px 2px 0 rgb(143, 95, 19);
}

header .container {
    background-color: #242424;
    padding: 20px;
}

@media screen and (max-width: 600px) {
    header {
        font-size: 4vw;
    }
}

</style>