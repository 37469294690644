import { createRouter, createWebHistory } from "vue-router";
//import store from '@/store'

// Views
import MainPage from "../views/MainPage.vue"
//import SchoolProj from "../views/SchoolProjects.vue"
import NotFound from "../views/NotFound.vue"

const routes = [{
        name: 'main',
        path: '/',
        component: MainPage,
        meta: {
            title: 'Home Page',
            requiresAuth: false
        }
    },
    /* {
        name: 'schoolprojects',
        path: '/schoolprojects',
        component: SchoolProj,
        meta: {
            title: 'School Projects',
            requiresAuth: false
        }
    }, */
    {
        name: 'notFound',
        path: '/:pathMatch(.*)',
        component: NotFound,
        meta: {
            title: '404 Not Found',
            requiresAuth: false
        }
    }
];


const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.afterEach((to) => {
    document.title = to.meta.title;
});
/*
router.beforeEach((to, from, next) => {
    /*
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters.authentificated) {
            next()
        } else {
            next('/SignIn')
        }
    } else {
        next()
    }
    */
//})

export default router;