<template>
    <div class="container">
        <div class="flex-main-wraper">
            <Header :title="'Welcome !'" />
            <aside class="sidebar" id="card-bar">
                <div class="full-border">
                    <div class="container">
                        <h1>About me</h1>
                        <img src="../assets/mooncake.jpg">
                        <h3>clcondorcet</h3>
                        <div>
                            <a class="link" href="https://github.com/clcondorcet" target="_blank">My Github Page</a>
                        </div>
                    </div>
                </div>
            </aside>
            <div class="flex-content-wraper">
                <NavigationBar :navTable="navigation" />
                <article>
                    <section id="minecraft-plugins">
                        <h1 class="article-title">Minecraft Plugins</h1>
                        <div class="article-content">
                            <div class="subsection">
                                <div class="subsection-img">
                                    <a href="https://www.spigotmc.org/resources/itemsorter.85370/">
                                        <img src="../assets/ItemSorter.png">
                                    </a>
                                </div>
                                <div class="subsection-content">
                                    <h2>
                                        ItemSorter
                                    </h2>
                                    <hr>
                                    <h3>
                                        What is it?
                                    </h3>
                                    <p>
                                        ItemSorter is a plugin to sort your items automatically in your chests. 
                                        It can replace all laggy hoppers from your servers.
                                        Works on 1.8 up to 1.19.3! (not tested on past 1.8 versions)
                                    </p>
                                    <h3>Features</h3>
                                    <p>- You can make big storage system that store all kind of items according to their id.</p>
                                    <p>- You can store easily non-stackable items.</p>
                                    <p>- You can have multiple types of items stored in one chest.</p>
                                    <p>- You can add trusts players that can manage your storage system.</p>
                                    <p>- Any player can create a storage system.</p>
                                    <p>- You can modify the range of the storage system.</p>
                                    <p>- You can store items in a chest, double chest or a barrel!</p>
                                    <br>
                                    <a class="link" href="https://www.spigotmc.org/resources/itemsorter.85370/" target="_blank">Click here to see the spigot page!</a>
                                </div>
                            </div>
                        </div>
                    </section>
                </article>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
/*
<h2>Clément Rostagni</h2>
<p>
    I'm a french Student in EFREI Paris, a french IT engineer school.
</p>
<div>
    <a class="link" href="https://www.linkedin.com/in/clement-rostagni/" target="_blank">My Linkedin</a>
</div>
*/
import Header from "../components/Header.vue"
import NavigationBar from "../components/NavBar.vue"
import Footer from "../components/Footer.vue"

export default {
    name: "MainPage",
    components: {
        Header, NavigationBar, Footer
    },
    data() {
        return {
            navigation: this.$store.getters.navigation
        }
    }
}
</script>

<style>

/*  Mobile  */

#card-bar {
    order: 2;
}

/*  Tablet  */

@media screen and (min-width: 600px) and (max-width: 900px) {
    #card-bar {
        order: 1;
        flex-shrink: 0;
        flex-basis: 20%;
        max-width: 20%;
    }
}

/*  Desktop  */

@media screen and (min-width: 900px) {
    #card-bar {
        order: 1;
        flex-shrink: 0;
        flex-basis: 20%;
        max-width: 20%;
    }
}

/*  Side Bars  */

.sidebar .container {
    padding: 20px;
    background-color: #040404;
    color: #c4c196;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
}

.sidebar .container>* {
    flex-basis: 100%;
    height: min-content;
}

.sidebar>* {
    position: sticky;
    position: -webkit-sticky;
    top: 2px;
}

.sidebar img {
    transition: all .3s ease-in-out;
}

.sidebar img:hover {
    transform: scale(105%);
}

@media screen and (max-width: 600px) {
    .sidebar h2 {
        font-size: 4vw;
    }
    .sidebar h1 {
        font-size: 4.5vw;
    }
    .sidebar * {
        font-size: 3vw;
    }
}

/*  Card Bar  */

#card-bar img {
    border-radius: 50%;
    max-width: 100%;
}

/*  Content  */

article {
    display: flex;
    row-gap: 20px;
    flex-direction: column;
    justify-content: center;
}

article>section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0;
}

article>section .article-title {
    flex-shrink: 1;
    align-self: flex-start;
    background-color: rgb(233, 233, 233);
    padding: 10px;
    padding-left: 10%;
    padding-right: 10%;
    z-index: 1;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin: 0;
    margin-bottom: -5px;
    border-left: 5px solid rgba(219, 154, 32, 1);
    border-top: 5px solid rgba(219, 154, 32, 1);
    border-right: 5px solid rgba(219, 154, 32, 1);
    color: rgba(221, 148, 31, 1);
    text-shadow: 1px 1px 0 rgb(143, 95, 19);
    font-family: 'Fredoka One', serif;
    font-size: large;
}

article>section .article-content {
    background-color: #000000;
    flex-grow: 1;
    flex-basis: 100%;
    border: 5px solid rgba(219, 154, 32, 1);
    padding: 20px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    color: rgba(219, 154, 32, 1);
}

article>section .article-content.notitle {
    border-top-left-radius: 10px;
}

article>section .article-content>.subsection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

article>section .article-content>.subsection>.subsection-content {
    flex-grow: 2;
    flex-basis: 65%;
}

article>section .article-content>.subsection>.subsection-content>h1 {
    color: rgb(223, 105, 0);
    text-shadow: 1px 1px 0 rgb(155, 96, 20);

}

article>section .article-content>.subsection>.subsection-content>h2 {
    color: rgb(223, 105, 0);
    text-shadow: 1px 1px 0 rgb(155, 96, 20);
}

article>section .article-content>.subsection>.subsection-content>h3 {
    color: rgb(223, 105, 0);
    text-shadow: 1px 1px 0 rgb(155, 96, 20);
}

article>section .article-content>.subsection>.subsection-img {
    flex-grow: 1;
    flex-basis: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    gap: 10px;
}

@media screen and (min-width: 1200px) {
    article>section .article-content>.subsection>.subsection-img {
        flex-basis: 30%;
    }
    /*
    article>section img {
        transition: all .5s ease-in;
    }

    article>section img:hover {
        transform: scale(130%);
        position: relative;
        z-index: 2;
    }
    */
}

article>section .article-content>.subsection>.subsection-img * {
    max-width: 100%;
}

.image-layout {
    display: flex;
    flex-wrap: wrap;
}

.image-layout>* {
    height: 20vh;
    flex-grow: 1;
}

.image-layout img {
    max-height: 100%;
    min-width: 100%;
    vertical-align: bottom;
}

.form-wrapper {
    padding: 20px;
    display: flex;
    flex-flow: row wrap;
    gap: 10px 10px;
    justify-content: center;
}

.form-wrapper>input {
    flex-grow: 1;
    padding: 5px;
    border: 3px solid rgba(219, 154, 32, 1);
    background-color: #242424;
    color: white;
}

.form-wrapper>#fname {
    flex-basis: 45%;
}

.form-wrapper>#lname {
    flex-basis: 45%;
}

.form-wrapper>#email {
    flex-basis: 100%;
}

.form-wrapper>button {
    padding: 10px;
    border-radius: 0;
    border: 0px;
    color: white;
    font-family: 'Fredoka One', serif;
    font-size: large;
    background-color: #494949;
    box-shadow: 2px 2px #303030;
}

.form-wrapper>button:active {
    background-color: #3d3d3d;
    box-shadow: -2px -2px #303030;
}

</style>