<template>
  <div class="container">
        <div class="flex-main-wraper">
            <Header :title="'Page not found :/'" />
            <div class="flex-content-wraper">
                <NavigationBar :navTable="navigation" />
                <article>
                    <section id="NotFound">
                        <div class="article-content notitle">
                            <h1>
                                This page does not exists !
                            </h1>
                            <img src="../assets/mooncakesad.png" alt="">
                        </div>
                    </section>
                </article>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from "../components/Header.vue"
import NavigationBar from "../components/NavBar.vue"
import Footer from "../components/Footer.vue"

export default {
    name: "MainPage",
    components: {
        Header, NavigationBar, Footer
    },
    data() {
        return {
            navigation: this.$store.getters.navigation
        }
    }
}
</script>

<style scoped>

h1 {
    text-align: center;
    font-family: 'Fredoka One', serif;
    font-size: x-large;
    color: rgba(221, 148, 31, 1);
}

img {
    max-width: 50%;
}

</style>