<template>
    <footer>
        <div class="line-border-top">
            <div class="line-border-bottom">
                <div class="container">
                    <h2>
                        Some links
                    </h2>
                    <div><a class="link" href="https://github.com/clcondorcet" target="_blank">My Github</a></div>
                    <div><a class="link" href="https://www.spigotmc.org/members/clcondorcet.189336/#resources" target="_blank">My Spigot</a></div>
                    <h2>
                        Contact me
                    </h2>
                    <div><a class="link" href="https://discord.gg/7M3tth85z7" target="_blank">My Discord</a></div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
//<div><a class="link" href="https://www.linkedin.com/in/clement-rostagni/" target="_blank">My Linkedin</a></div>
export default {

}
</script>


<style scoped>

footer {
    margin-top: 10px;
}

footer .container {
    background-color: #242424;
    padding: 20px;
    color: rgba(219, 154, 32, 1);
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
    gap: 20px 50px;
}

footer .container>h2 {
    flex-basis: 100%;
}

footer .container>p {
    margin: 0;
}

</style>