<template>
    <router-view/>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
#app {
  text-align: center;
}

* {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: #242424;
}

/* Links animation */

.link {
    text-decoration: none;
    color: rgb(223, 190, 0);
    position: relative;
}

.link::before {
    content: "";
    border-bottom: 1px solid rgb(223, 190, 0);
    right: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: right .3s ease-out;
}

.link:hover::before {
    right: 0;
}

/*  borders utils  */

.line-border-top {
    background-image: linear-gradient(90deg, rgba(36, 36, 36, 1) 30%, rgba(221, 148, 31, 1) 88%, rgba(36, 36, 36, 1) 93%);
    padding-top: 10px;
}

.line-border-bottom {
    background-image: linear-gradient(270deg, rgba(36, 36, 36, 1) 30%, rgba(221, 148, 31, 1) 88%, rgba(36, 36, 36, 1) 93%);
    padding-bottom: 10px;
}

.full-border {
    padding: 5px;
    background-image: linear-gradient(45deg, rgba(189, 86, 60, 1) 24%, rgba(219, 154, 32, 1) 68%);
    border-radius: 20px;
}

.full-border .container:first-child {
    border-radius: 15px;
}

/* Title Utils */

h1+h2 {
    margin-top: 0%;
}

h2+h3 {
    margin-top: 0%;
}

/*  Mobile  */

.flex-main-wraper {
    display: flex;
    flex-direction: column;
    gap: 20px 20px;
    text-align: center;
}

.flex-content-wraper {
    order: 1;
    display: flex;
    flex-direction: column;
    gap: 20px 20px;
}

/*  Tablet  */

@media screen and (min-width: 600px) and (max-width: 900px) {
    .flex-main-wraper {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .flex-content-wraper {
        order: 2;
        flex-grow: 1;
        flex-basis: 65%;
    }
}

/*  Desktop  */

@media screen and (min-width: 900px) {
    .flex-main-wraper {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .flex-content-wraper {
        order: 2;
        flex-grow: 1;
        flex-basis: 50%;
    }
}

</style>
