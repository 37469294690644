import { createStore } from 'vuex'

export default createStore({
    state: {
        /*
        token: "",
        user: {},
        authentificated: false
        */
        navigation: [{
                display: "Home",
                href: "",
                isroute: true,
                route: "/"
            },
            {
                display: "Minecraft",
                href: "",
                isroute: true,
                route: "/minecraft"
            },
            {
                display: "Archives",
                href: "",
                isroute: true,
                route: "/archives"
            },
            // {
            //     display: "School Projects",
            //     href: "",
            //     isroute: true,
            //     route: "/schoolprojects"
            // },
            // {
            //     display: "Cr'Hack Share V2",
            //     href: "",
            //     isroute: true,
            //     route: "/crhack-share"
            // },
            {
                display: "Predecessor BuildMaker",
                href: "/predecessor",
                isroute: false,
                route: ""
            },
        ]
    },
    getters: {
        /*
        token: (state) => state.token,
        user: (state) => state.user,
        authentificated: (state) => state.authentificated
        */
        navigation: (state) => state.navigation,
    },
    mutations: {
        /*
        SET_TOKEN(state, token) {
            state.token = token
        },
        SET_USER(state, user) {
            state.user = user
        },
        SET_AUTHENTIFICATED(state, authentificated) {
            state.authentificated = authentificated
        },
        */
    },
    actions: {
        /*
        setToken(context, token) {
            context.commit("SET_TOKEN", token)
        },
        addUser(context, user) {
            context.commit("SET_USER", user)
            context.commit("SET_AUTHENTIFICATED", true)
        },
        logOut(context) {
            context.commit("SET_USER", null)
            context.commit("SET_AUTHENTIFICATED", false)
        }
        */
    },
    modules: {}
})