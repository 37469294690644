<template>
    <nav>
        <div class="container">
            <ul>
                <li v-for="(item, index) in navTable" :key="index">
                    <div class="animation-border">
                        <router-link v-if="item.isroute" :to="item.route">{{ item.display }}</router-link>
                        <a v-else :href="item.href">{{ item.display }}</a>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
export default {
    name: "NavBar",
    props: {
        navTable: Array
    },
    methods: {
        getNav: function () {
            let nav = []
            console.log(this.$route.path)
            for (let index in this.navTable) {
                let page = this.navTable[index]
                if (page.route != this.$route.path) {
                    nav.push(page)
                }
            }
            return nav
        }
    },
}
</script>

<style scoped>
/*  Navigation  */

nav {
    z-index: 2;
}

nav .container {
    color: #c4c196;
    border: 2px solid black;
    transition: all .2s ease-in-out;
}

nav .container:hover {
    border: 2px solid #242424;
}

nav .container>ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

nav .container>ul>li {
    background-color: #242424;
    text-align: center;
    transition: background-color 0.5s;
    flex-grow: 1;
}

nav .container>ul>li a {
    background-color: #242424;
    display: inline-block;
    text-decoration: none;
    color: white;
    font-family: 'Fredoka One', serif;
    width: 100%;
    padding-bottom: 20px;
    padding-top: 20px;
    transition: all .2s ease-in-out;
}

nav .container>ul>li a:hover {
    transform: scale(90%);
    background-color: #202020;
    color: rgba(219, 154, 32, 1);
    text-shadow: 1px 1px 0 rgb(143, 95, 19);
}

.animation-border {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(45deg, rgba(189, 86, 60, 1) 24%, rgba(219, 154, 32, 1) 68%);
    transition: all .2s ease-in-out;
}

@media screen and (min-width: 600px) {
    nav {
        position: sticky;
        position: -webkit-sticky;
        top: 2px;
    }
    nav .container>ul {
        flex-direction: row;
        flex-wrap: nowrap;
    }
}
</style>